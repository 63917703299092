import Image from 'next/image';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { COURSE_END_POINT } from 'src/api/course';
import Button from 'src/components/atoms/button';
import NextLink from 'src/components/atoms/link';
import { getAssetUrl } from 'src/helpers/utils';
import { ICourse } from 'src/interfaces';
import useSWR from 'swr';

import AllCourseSectionStyle from './style';

const AllCourseSection = () => {
  const { t } = useTranslation();
  const { data } = useSWR<ICourse[]>(COURSE_END_POINT.COUNT);
  const courseCount = Math.floor(+data! / 100) * 100;

  return (
    <AllCourseSectionStyle>
      <Image
        src={getAssetUrl('AllCourseBanner.png')}
        alt="background-all-course"
        layout="fill"
        objectFit="cover"
        objectPosition="center"
        priority
      />
      <Box className="container">
        <Typography variant="h1" fontWeight="bold" sx={{ marginBottom: 2 }}>
          {t('home.section.all_course_title')}
        </Typography>
        <Typography variant="h4" sx={{ fontFamily: 'Lanna' }}>
          {t('home.section.all_course_content1', { amount: courseCount })}
        </Typography>
        <Typography variant="h4" sx={{ fontFamily: 'Lanna', marginBottom: 5 }}>
          {t('home.section.all_course_content2')}
        </Typography>
        <NextLink href="/courses/all" target="_blank">
          <Button variant="contained" color="secondary" className="all-course-button">
            {t('home.section.explore_all_course')}
          </Button>
        </NextLink>
      </Box>
    </AllCourseSectionStyle>
  );
};

export default AllCourseSection;
