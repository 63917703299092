import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const AllCourseSection = styled(Box)(
  ({ theme }) => `

  img {
    z-index: 0;
  }
  height: 300px;
  width: 100%;
  position: relative;
  color: white;
  display: flex;

  ${theme.breakpoints.down('md')} {
    height: 320px;
    text-align: center;
    h1 {
      font-size: 24px;
    }
    h4 {
      font-size: 18px;
    }
  }

  .container {
    z-index: 1;
    max-width: 1150px;
    width: 100%;
    margin: 60px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${theme.breakpoints.down('lg')} {
      padding: 0px 20px;
    }
    ${theme.breakpoints.down('sm')} {
      padding: 0px 15px;
    }

    @media (max-width: 1999px) and (min-width: 1701px) {
      max-width: 1440px;
    }
    @media (min-width: 2000px) {
      max-width: 1730px;
    }
  }

  .all-course-button {
    background: #FFFFFF;
    color: #00532A;
    font-size: 18px;
    &:hover {
      background-color: #cbcbcb;
    }
  }
`,
);

export default AllCourseSection;
